<template>
  <el-row :gutter="20">
    <el-col
      :xs="24"
      :sm="24"
      :md="24"
      :lg="12"
      :xl="12"
    >
      <div class="heading">
        <h1 v-if="!myteamid">
          Add My Team
        </h1>
        <h1 v-if="myteamid">
          Edit My Team
        </h1>
      </div>
    </el-col>
    <el-col
      :xs="6"
      :sm="6"
      :md="6"
      :lg="12"
      :xl="12"
    >
      <div class="topsearch">
        <el-button
          type="primary"
          @click="myteam()"
        >
          My Team
        </el-button>
        <el-button
          type="success"
          @click="submitForm('ruleForm')"
        >
          Save
        </el-button>
      </div>
    </el-col>
  </el-row>
  <el-row :gutter="20">
    <el-col
      :xs="24"
      :sm="24"
      :md="24"
      :lg="24"
      :xl="24"
    >
      <div v-if="showAlertAdded">
        <el-alert
          v-if="!myteamid"
          title="Myteam Added Successfully"
          type="success"
          effect="dark"
        />
        <el-alert
          v-if="myteamid"
          title="Myteam Updated Successfully"
          type="success"
          effect="dark"
        />
      </div>
      <div v-if="showAlertSlugexit">
        <el-alert
          title="The slug has already been taken."
          type="error"
          effect="dark"
        />
      </div>
    </el-col>
  </el-row>

  <el-form
    ref="ruleForm"
    :model="data"
    status-icon
    :rules="rules"
    label-width="180px"
    class=" login-form"
    :label-position="labelPosition"
  >
    <el-tabs type="border-card">
      <el-tab-pane label="Team Info">  
        <div class="panel panel-default">
          <el-row :gutter="20">
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            >
              <el-form-item
                label="First Name"
                prop="fname"
              >
                <el-input
                  v-model="data.fname"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            > 
              <el-form-item
                label="Last Name"
                prop="lname"
              >
                <el-input
                  v-model="data.lname"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            >
              <el-form-item
                label="Email"
                prop="email"
              >
                <el-input
                  v-model="data.email"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            >
              <el-form-item
                label="Password"
                prop="password"
              >
                <el-input
                  v-model="data.password"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            >
              <el-form-item
                label="Designation"
                prop="designation"
              >
                <el-input
                  v-model="data.designation"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            >
              <el-form-item
                label="Postal/Zip Code"
                prop="postalcode"
              >
                <el-input
                  v-model="data.postalcode"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
          </el-row>    
          <el-row :gutter="20">
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            >
              <el-form-item
                label="Country"
                prop="country"
              >
                <el-input
                  v-model="data.country"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            >
              <el-form-item
                label="State"
                prop="state"
              >
                <el-input
                  v-model="data.state"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
          </el-row>  
          <el-row :gutter="20">
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            >
              <el-form-item
                label="City"
                prop="city"
              >
                <el-input
                  v-model="data.city"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            >
              <el-form-item
                label="Address"
                prop="address"
              >
                <el-input
                  v-model="data.address"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
          </el-row> 
          <el-row :gutter="20">
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            >
              <el-form-item
                label="Phone"
                prop="phone"
              >
                <el-input
                  v-model="data.phone"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            >
              <el-form-item
                label="Agent Board ID/Numbe"
                prop="vnumber"
              >
                <el-input
                  v-model="data.vnumber"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
          </el-row> 
          <el-row :gutter="20">
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            >
              <el-form-item
                label="Brokerage"
                prop="brokerage"
              >
                <el-input
                  v-model="data.brokerage"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            >
              <el-form-item
                label="Website"
                prop="website"
              >
                <el-input
                  v-model="data.website"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col
              :span="24"
              :xs="24"
              :sm="24"
              :md="24"
              :lg="24"
              :xl="12"
            >
              <el-form-item
                label="Description"
                prop="description"
              >
               <TinyMce
              v-if="loadEditor"
              v-bind:content="data.description"
              @contentChanged="updateTextAreaValue"
          >
          </TinyMce>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            >
              <el-form-item 
                label="Photo"
                prop="photo"
              >
                <el-upload
                  class="upload-demo"
                  drag
                  :action="imageUploadUrl"
                  list-type="picture"
                  :headers="imageUploadHeaders"
                  name="file"
                  :file-list="fileList"
                  :limit="1"
                  :on-preview="handlePreview"
                  :on-remove="handleRemove"
                  :on-success="handleImageUploadSuccess"
                  :on-error="handleImageUploadError"
                >
                  <i class="el-icon-upload" />
                  <div class="el-upload__text">
                    Drop file here or <em>click to upload</em><br>
                    JPEG,PNG,JPG,GIF,SVG,WEBP files with a size less than 10M
                  </div>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row> 
          <el-row :gutter="20">
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            >
              <el-form-item
                label="Meta Tag Title"
                prop="meta_title"
              >
               
              <el-input
          v-model="data.meta_title"
          type="textarea"
        />
              </el-form-item>
            </el-col>
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            >
              <el-form-item
                label="Meta Tag Description"
                prop="meta_description"
              >
              <el-input
          v-model="data.meta_description"
          type="textarea"
        />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            >
           
              <el-form-item
                label="Meta Tag Keywords"
                prop="meta_keyword"
              > <el-input
          v-model="data.meta_keyword"
          type="textarea"
        />
              </el-form-item>
            </el-col>
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            >
              <el-form-item
                label="Status"
                prop="status"
              >
                <el-select
                  v-model="data.status"
                  placeholder="Select"
                >
                  <el-option
                    v-for="item in selectstatus"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            >
              <el-form-item>
                <input
                  id="myteamid"
                  v-model="myteamid"
                  name="myteamid"
                  type="hidden"
                >
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Lead Routing">
        <div class="panel panel-default">
          <el-row :gutter="20">
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            >
              <el-form-item
                label="Min Price"
                prop="min_price"
              >
                <el-input
                  v-model="data.min_price"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            > 
              <el-form-item
                label="Max Price"
                prop="max_price"
              >
                <el-input
                  v-model="data.max_price"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            >
              <el-form-item
                label="Min Area"
                prop="min_area"
              >
                <el-input
                  v-model="data.min_area"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            > 
              <el-form-item
                label="Max Area"
                prop="max_area"
              >
                <el-input
                  v-model="data.max_area"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            >
              <el-form-item
                label="Min Score"
                prop="min_score"
              >
                <el-input
                  v-model="data.min_score"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            > 
              <el-form-item
                label="Max Score"
                prop="max_score"
              >
                <el-input
                  v-model="data.max_score"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            >
              <el-form-item
                label="Listing Type"
                prop="listing_type"
              >
                <el-select
                  v-model="data.listing_type"
                  placeholder="Select"
                >
                  <el-option
                    v-for="item in ListingType"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            />
          </el-row>
        </div>
      </el-tab-pane>
    </el-tabs>
  </el-form>
</template>
<script>
import MyteamService from "@/api/myteam.service";
import authHeader from '@/api/auth-header';
import TinyMce from "@/components/TinyMce"
  export default {
       components: {
      TinyMce
    },
    data() {
      var checkFname = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Please Enter the First Name.'));
        } else {
          callback();
        }
      };
      var checkDescription = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Please Enter the Description.'));
        } else {
          callback();
        }
      };
      var checkEmail = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Please Enter the Email.'));
        } else {
          callback();
        }
      };
      var checkPassword = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Please Enter the Password.'));
        } else {
          callback();
        }
      };
      return {
        showAlertAdded: false,
        showAlertSlugexit: false,
        labelPosition: 'top',
        myteamid: this.$route.params.myteam,
        imageUploadUrl: process.env.VUE_APP_BASE_API,
        imageUploadHeaders: null,
		selectstatus:[{
          value: 'Enabled',
          label: 'Enabled'
        }, {
          value: 'Disabled',
          label: 'Disabled'
        }],
       ListingType:[{
          value: 'All',
          label: 'All'
        }, {
          value: '1/2 Duplex',
          label: '1/2 Duplex'
        }, {
          value: 'Apartment Unit',
          label: 'Apartment Unit'
        }, {
          value: 'Duplex',
          label: 'Duplex'
        }],
        fileList: [],
        data: {
          lname: null,
          photo: null,
          imageNew: null,
          description: null,
          slug: null,
          meta_title: null,
          meta_description: null,
          meta_keyword: null,
          posted_on: null,
          status: "Enabled",
        },
        // ruleForm: {
        //   name: '',
        //   image: ''
        // },
        rules: {
          fname: [
            { validator: checkFname, trigger: 'blur' }
          ],
          description: [
            { validator: checkDescription, trigger: 'blur' }
          ],         
          email: [
            { validator: checkEmail, trigger: 'blur' }
          ],
           password: [
            { validator: checkPassword, trigger: 'blur' }
          ]
        }
      };
    },
	mounted() {
    this.setImageUploadUrl()
    if(this.myteamid){
      this.getblog();
    }
  },
   computed: {
      loadEditor: function () {
        return !this.myteamid || (this.myteamid && this.data.description);
        },
  },
    methods: {
       updateTextAreaValue(value) {
        this.data.description = value;
      },
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid && !this.myteamid) {
             return MyteamService.add(this.data).then(response => {
                this.showAlertAdded=true;
              });
          } else if (valid && this.myteamid) {
            return MyteamService.update(this.myteamid, this.data).then(response => {
              this.showAlertAdded=true;
            });
          }else {
            console.log('error submit!!');
            return false;
          }
        });
      },handleFileUpload(){

      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
	myteam(){
		this.$router.push("/myteam");
	},
	getblog(){
    return MyteamService.get(this.myteamid).then(response => {
        this.data = response.data.data;
        this.fileList.push({name:'', url: response.data.data.image});
        return response;
      });
	},
      setImageUploadUrl() {
        this.imageUploadHeaders = authHeader();
        this.imageUploadUrl = process.env.VUE_APP_BASE_API + 'image-upload';
      },
      handleImageUploadSuccess(response, file, fileList) {
        if(response.code == 200) {
          this.data.imageNew = response.image_url;
        }
      },
      handleImageUploadError(err, file, fileList) {
        console.log(err)
      },
      handlePreview(file) {
        console.log(file)
        //TODO: Any Preview stuff
      },
      handleRemove(file, fileList) {
        console.log(file, fileList)
        //TODO: Delete the file
      },
    }
  }
</script>
<style lang="scss" scoped>
.panel {
    position: relative;
    width: 100%;
    margin-bottom: 10px;
    padding: 10px 17px;
    display: inline-block;
    background: #fff;
    border: 1px solid #E6E9ED;
    -webkit-column-break-inside: avoid;
    -moz-column-break-inside: avoid;
    opacity: 1;
    transition: all .2s ease;
}
.el-row {
    margin-bottom: 0px !important;
}
.el-form--label-top .el-form-item__label{
      float: left !important;
      font-weight: 500;
      font-size: 12px;
      padding: 0 0 0px 0;
}
.el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }  
  .heading {
  text-align: left;
  width: 100%;
  line-height: 2;
}
    .login {
        flex: 1;
		width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
   .login-form {
        width: 100%;
    }
    .selectstatus{
      -webkit-appearance: none;
    background-color: #FFFFFF;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: inherit;
    height: 40px;
    line-height: 40px;
    outline: none;
    padding: 0 15px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
    }
div .topsearch {
    float: right;
    display: inline-flex;
    padding: 15px 0px;
  }
div .topsearch .el-input__inner{
  padding-right:10px;
  height: 40px;
  width: 500px;
}
.el-select{
     width: 100%;
}
div .topsearch .el-input{
  padding-right:10px;
}
.pagination{ 
  padding: 12px 5px;
  float: right;
}
</style>